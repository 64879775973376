import { merchantAdminSplitApi as api } from "./apiBase";
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        merchantsControllerRetrieveMerchants: build.query<
            MerchantsControllerRetrieveMerchantsApiResponse,
            MerchantsControllerRetrieveMerchantsApiArg
        >({
            query: (queryArg) => ({
                url: `/merchants`,
                params: {
                    PageNumber: queryArg.pageNumber,
                    PageSize: queryArg.pageSize,
                },
            }),
        }),
        merchantsControllerCreateMerchant: build.mutation<
            MerchantsControllerCreateMerchantApiResponse,
            MerchantsControllerCreateMerchantApiArg
        >({
            query: (queryArg) => ({
                url: `/merchants`,
                method: "POST",
                body: queryArg.createMerchantCommand,
            }),
        }),
        merchantsControllerRetrieveMerchant: build.query<
            MerchantsControllerRetrieveMerchantApiResponse,
            MerchantsControllerRetrieveMerchantApiArg
        >({
            query: (queryArg) => ({ url: `/merchants/${queryArg.merchantId}` }),
        }),
        merchantsControllerUpdateMerchant: build.mutation<
            MerchantsControllerUpdateMerchantApiResponse,
            MerchantsControllerUpdateMerchantApiArg
        >({
            query: (queryArg) => ({
                url: `/merchants/${queryArg.merchantId}`,
                method: "PUT",
                body: queryArg.merchantDto,
            }),
        }),
        merchantsControllerUpdateMerchantConfiguration: build.mutation<
            MerchantsControllerUpdateMerchantConfigurationApiResponse,
            MerchantsControllerUpdateMerchantConfigurationApiArg
        >({
            query: (queryArg) => ({
                url: `/merchants/${queryArg.merchantId}/configuration`,
                method: "PUT",
                body: queryArg.merchantDto,
            }),
        }),
        merchantUsersControllerRetrieveMerchantUsers: build.query<
            MerchantUsersControllerRetrieveMerchantUsersApiResponse,
            MerchantUsersControllerRetrieveMerchantUsersApiArg
        >({
            query: (queryArg) => ({
                url: `/merchants/${queryArg.merchantId}/users/${queryArg.page}`,
            }),
        }),
        merchantUsersControllerRetrieveUserGroups: build.query<
            MerchantUsersControllerRetrieveUserGroupsApiResponse,
            MerchantUsersControllerRetrieveUserGroupsApiArg
        >({
            query: (queryArg) => ({
                url: `/merchants/${queryArg.merchantId}/user-groups`,
            }),
        }),
        merchantUsersControllerUpdateUserGroup: build.mutation<
            MerchantUsersControllerUpdateUserGroupApiResponse,
            MerchantUsersControllerUpdateUserGroupApiArg
        >({
            query: (queryArg) => ({
                url: `/merchants/${queryArg.merchantId}/user-groups`,
                method: "POST",
                body: queryArg.userGroupDto,
            }),
        }),
        merchantUsersControllerCreateMerchantUser: build.mutation<
            MerchantUsersControllerCreateMerchantUserApiResponse,
            MerchantUsersControllerCreateMerchantUserApiArg
        >({
            query: (queryArg) => ({
                url: `/merchants/${queryArg.merchantId}/users`,
                method: "POST",
                body: queryArg.createMerchantUserCommand,
            }),
        }),
        merchantUsersControllerUpdateMerchantUser: build.mutation<
            MerchantUsersControllerUpdateMerchantUserApiResponse,
            MerchantUsersControllerUpdateMerchantUserApiArg
        >({
            query: (queryArg) => ({
                url: `/merchants/${queryArg.merchantId}/users/${queryArg.userId}`,
                method: "PUT",
                body: queryArg.updateMerchantUserCommand,
            }),
        }),
        productCategoriesControllerCreateProductCategory: build.mutation<
            ProductCategoriesControllerCreateProductCategoryApiResponse,
            ProductCategoriesControllerCreateProductCategoryApiArg
        >({
            query: (queryArg) => ({
                url: `/product-categories/${queryArg.merchantId}`,
                method: "POST",
                body: queryArg.categoryDto,
            }),
        }),
        productCategoriesControllerRetrieveCategories: build.query<
            ProductCategoriesControllerRetrieveCategoriesApiResponse,
            ProductCategoriesControllerRetrieveCategoriesApiArg
        >({
            query: (queryArg) => ({
                url: `/product-categories/${queryArg.merchantId}`,
            }),
        }),
        productCategoriesControllerDeleteProductCategory: build.mutation<
            ProductCategoriesControllerDeleteProductCategoryApiResponse,
            ProductCategoriesControllerDeleteProductCategoryApiArg
        >({
            query: (queryArg) => ({
                url: `/product-categories/${queryArg.merchantId}/${queryArg.categoryName}`,
                method: "DELETE",
            }),
        }),
        productFunctionalFitControllerInitiateAttachmentUpload: build.mutation<
            ProductFunctionalFitControllerInitiateAttachmentUploadApiResponse,
            ProductFunctionalFitControllerInitiateAttachmentUploadApiArg
        >({
            query: (queryArg) => ({
                url: `/merchants/${queryArg.merchantId}/products/${queryArg.productCode}/attachments/init`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        productFunctionalFitControllerUpdateProductFitAssessment:
            build.mutation<
                ProductFunctionalFitControllerUpdateProductFitAssessmentApiResponse,
                ProductFunctionalFitControllerUpdateProductFitAssessmentApiArg
            >({
                query: (queryArg) => ({
                    url: `/merchants/${queryArg.merchantId}/products/${queryArg.productCode}/fit-assessment`,
                    method: "PUT",
                    params: {
                        fitDescription: queryArg.fitDescription,
                        fitApproved: queryArg.fitApproved,
                    },
                }),
            }),
        productFunctionalFitControllerCompleteAttachmentUpload: build.mutation<
            ProductFunctionalFitControllerCompleteAttachmentUploadApiResponse,
            ProductFunctionalFitControllerCompleteAttachmentUploadApiArg
        >({
            query: (queryArg) => ({
                url: `/merchants/${queryArg.merchantId}/products/${queryArg.productCode}/attachments/complete`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        productFunctionalFitControllerDeleteAttachment: build.mutation<
            ProductFunctionalFitControllerDeleteAttachmentApiResponse,
            ProductFunctionalFitControllerDeleteAttachmentApiArg
        >({
            query: (queryArg) => ({
                url: `/merchants/${queryArg.merchantId}/products/${queryArg.productCode}/attachments/${queryArg.attachmentId}`,
                method: "DELETE",
            }),
        }),
        productFunctionalFitControllerCreateFitExample: build.mutation<
            ProductFunctionalFitControllerCreateFitExampleApiResponse,
            ProductFunctionalFitControllerCreateFitExampleApiArg
        >({
            query: (queryArg) => ({
                url: `/merchants/${queryArg.merchantId}/products/${queryArg.productCode}/fit-examples`,
                method: "POST",
                body: queryArg.productFitExampleDto,
            }),
        }),
        productFunctionalFitControllerDeleteFitExample: build.mutation<
            ProductFunctionalFitControllerDeleteFitExampleApiResponse,
            ProductFunctionalFitControllerDeleteFitExampleApiArg
        >({
            query: (queryArg) => ({
                url: `/merchants/${queryArg.merchantId}/products/${queryArg.productCode}/fit-examples/${queryArg.scanId}`,
                method: "DELETE",
            }),
        }),
        productsControllerCreateProduct: build.mutation<
            ProductsControllerCreateProductApiResponse,
            ProductsControllerCreateProductApiArg
        >({
            query: (queryArg) => ({
                url: `/products`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        productsControllerRetrieveProducts: build.query<
            ProductsControllerRetrieveProductsApiResponse,
            ProductsControllerRetrieveProductsApiArg
        >({
            query: (queryArg) => ({
                url: `/products`,
                params: {
                    MerchantId: queryArg.merchantId,
                    ProductCode: queryArg.productCode,
                    ProductName: queryArg.productName,
                    GarmentType: queryArg.garmentType,
                    Gender: queryArg.gender,
                    CategoryTags: queryArg.categoryTags,
                    Page: queryArg.page,
                    PageSize: queryArg.pageSize,
                },
            }),
        }),
        productsControllerUpdateProduct: build.mutation<
            ProductsControllerUpdateProductApiResponse,
            ProductsControllerUpdateProductApiArg
        >({
            query: (queryArg) => ({
                url: `/products/${queryArg.merchantId}/${queryArg.productCode}`,
                method: "PUT",
                body: queryArg.body,
            }),
        }),
        productsControllerDeleteProduct: build.mutation<
            ProductsControllerDeleteProductApiResponse,
            ProductsControllerDeleteProductApiArg
        >({
            query: (queryArg) => ({
                url: `/products/${queryArg.merchantId}/${queryArg.productCode}`,
                method: "DELETE",
            }),
        }),
        productsControllerRetrieveMerchantProduct: build.query<
            ProductsControllerRetrieveMerchantProductApiResponse,
            ProductsControllerRetrieveMerchantProductApiArg
        >({
            query: (queryArg) => ({
                url: `/products/${queryArg.merchantId}/${queryArg.productCode}`,
            }),
        }),
        productsControllerRetrieveSizeChartCsv: build.query<
            ProductsControllerRetrieveSizeChartCsvApiResponse,
            ProductsControllerRetrieveSizeChartCsvApiArg
        >({
            query: (queryArg) => ({
                url: `/products/${queryArg.merchantId}/${queryArg.productCode}/chart`,
            }),
        }),
        productsControllerUpdateSizeChart: build.mutation<
            ProductsControllerUpdateSizeChartApiResponse,
            ProductsControllerUpdateSizeChartApiArg
        >({
            query: (queryArg) => ({
                url: `/products/${queryArg.merchantId}/${queryArg.productCode}/chart`,
                method: "PUT",
                body: queryArg.body,
            }),
        }),
        productsControllerRetrieveDimensionsForScanType: build.query<
            ProductsControllerRetrieveDimensionsForScanTypeApiResponse,
            ProductsControllerRetrieveDimensionsForScanTypeApiArg
        >({
            query: (queryArg) => ({
                url: `/products/dimensions/${queryArg.scanType}`,
            }),
        }),
        productsControllerBulkProductImport: build.mutation<
            ProductsControllerBulkProductImportApiResponse,
            ProductsControllerBulkProductImportApiArg
        >({
            query: (queryArg) => ({
                url: `/products/${queryArg.merchantId}/bulk`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        productsControllerBulkProductUpdate: build.mutation<
            ProductsControllerBulkProductUpdateApiResponse,
            ProductsControllerBulkProductUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/products/${queryArg.merchantId}/bulk`,
                method: "PUT",
                body: queryArg.body,
            }),
        }),
        productsControllerRetrieveBulkProducts: build.query<
            ProductsControllerRetrieveBulkProductsApiResponse,
            ProductsControllerRetrieveBulkProductsApiArg
        >({
            query: (queryArg) => ({
                url: `/products/${queryArg.merchantId}/bulk`,
                params: { example: queryArg.example },
            }),
        }),
        reportsControllerRetrieveProductSizingReport: build.query<
            ReportsControllerRetrieveProductSizingReportApiResponse,
            ReportsControllerRetrieveProductSizingReportApiArg
        >({
            query: (queryArg) => ({
                url: `/reports/product-sizing-report`,
                params: {
                    MerchantId: queryArg.merchantId,
                    GarmentTypes: queryArg.garmentTypes,
                    CategoryTags: queryArg.categoryTags,
                },
            }),
        }),
        sizeMatchControllerGetFitMetadata: build.mutation<
            SizeMatchControllerGetFitMetadataApiResponse,
            SizeMatchControllerGetFitMetadataApiArg
        >({
            query: (queryArg) => ({
                url: `/size-match/fit-comparison`,
                method: "POST",
                body: queryArg.retrieveFitMetadataQuery,
            }),
        }),
        userContextControllerRetrieveUserContext: build.query<
            UserContextControllerRetrieveUserContextApiResponse,
            UserContextControllerRetrieveUserContextApiArg
        >({
            query: () => ({ url: `/user-context` }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as merchantAdminApi };
export type MerchantsControllerRetrieveMerchantsApiResponse =
    /** status 200 OK */ MerchantDtoPagedList;
export type MerchantsControllerRetrieveMerchantsApiArg = {
    pageNumber?: number;
    pageSize?: number;
};
export type MerchantsControllerCreateMerchantApiResponse = unknown;
export type MerchantsControllerCreateMerchantApiArg = {
    createMerchantCommand: CreateMerchantCommand;
};
export type MerchantsControllerRetrieveMerchantApiResponse =
    /** status 200 OK */ MerchantDto;
export type MerchantsControllerRetrieveMerchantApiArg = {
    merchantId: number;
};
export type MerchantsControllerUpdateMerchantApiResponse =
    /** status 200 OK */ MerchantDto;
export type MerchantsControllerUpdateMerchantApiArg = {
    merchantId: number;
    merchantDto: MerchantDto;
};
export type MerchantsControllerUpdateMerchantConfigurationApiResponse =
    /** status 200 OK */ MerchantDto;
export type MerchantsControllerUpdateMerchantConfigurationApiArg = {
    merchantId: number;
    merchantDto: MerchantDto;
};
export type MerchantUsersControllerRetrieveMerchantUsersApiResponse =
    /** status 200 OK */ UserDtoPagedList;
export type MerchantUsersControllerRetrieveMerchantUsersApiArg = {
    merchantId: number;
    page: number;
};
export type MerchantUsersControllerRetrieveUserGroupsApiResponse =
    /** status 200 OK */ UserGroupDto[];
export type MerchantUsersControllerRetrieveUserGroupsApiArg = {
    merchantId: number;
};
export type MerchantUsersControllerUpdateUserGroupApiResponse =
    /** status 200 OK */ UserGroupDto[];
export type MerchantUsersControllerUpdateUserGroupApiArg = {
    merchantId: number;
    userGroupDto: UserGroupDto;
};
export type MerchantUsersControllerCreateMerchantUserApiResponse =
    /** status 200 OK */ UserDto;
export type MerchantUsersControllerCreateMerchantUserApiArg = {
    merchantId: number;
    createMerchantUserCommand: CreateMerchantUserCommand;
};
export type MerchantUsersControllerUpdateMerchantUserApiResponse =
    /** status 200 OK */ UserDto;
export type MerchantUsersControllerUpdateMerchantUserApiArg = {
    merchantId: number;
    userId: string;
    updateMerchantUserCommand: UpdateMerchantUserCommand;
};
export type ProductCategoriesControllerCreateProductCategoryApiResponse =
    /** status 200 OK */ CategoryDto;
export type ProductCategoriesControllerCreateProductCategoryApiArg = {
    merchantId: number;
    categoryDto: CategoryDto;
};
export type ProductCategoriesControllerRetrieveCategoriesApiResponse =
    /** status 200 OK */ CategoryDto[];
export type ProductCategoriesControllerRetrieveCategoriesApiArg = {
    merchantId: number;
};
export type ProductCategoriesControllerDeleteProductCategoryApiResponse =
    unknown;
export type ProductCategoriesControllerDeleteProductCategoryApiArg = {
    merchantId: number;
    categoryName: string;
};
export type ProductFunctionalFitControllerInitiateAttachmentUploadApiResponse =
    /** status 200 OK */ AttachmentDto[];
export type ProductFunctionalFitControllerInitiateAttachmentUploadApiArg = {
    merchantId: number;
    productCode: string;
    body: string[];
};
export type ProductFunctionalFitControllerUpdateProductFitAssessmentApiResponse =
    /** status 200 OK */ ProductSummaryDto;
export type ProductFunctionalFitControllerUpdateProductFitAssessmentApiArg = {
    merchantId: number;
    productCode: string;
    fitDescription?: string;
    fitApproved?: boolean;
};
export type ProductFunctionalFitControllerCompleteAttachmentUploadApiResponse =
    /** status 200 OK */ AttachmentDto[];
export type ProductFunctionalFitControllerCompleteAttachmentUploadApiArg = {
    merchantId: number;
    productCode: string;
    body: AttachmentDto[];
};
export type ProductFunctionalFitControllerDeleteAttachmentApiResponse = unknown;
export type ProductFunctionalFitControllerDeleteAttachmentApiArg = {
    merchantId: number;
    productCode: string;
    attachmentId: number;
};
export type ProductFunctionalFitControllerCreateFitExampleApiResponse =
    /** status 200 OK */ ProductFitExampleDto;
export type ProductFunctionalFitControllerCreateFitExampleApiArg = {
    merchantId: number;
    productCode: string;
    productFitExampleDto: ProductFitExampleDto;
};
export type ProductFunctionalFitControllerDeleteFitExampleApiResponse =
    /** status 200 OK */ ProductFitExampleDto;
export type ProductFunctionalFitControllerDeleteFitExampleApiArg = {
    merchantId: number;
    productCode: string;
    scanId: string;
};
export type ProductsControllerCreateProductApiResponse =
    /** status 200 OK */ ProductSummaryDto;
export type ProductsControllerCreateProductApiArg = {
    body: {
        Name?: string;
        ProductCode?: string;
        MerchantId?: number;
        GarmentType?: GarmentType;
        Gender?: GarmentGender;
        CategoryTags?: string[];
        Image?: Blob;
    };
};
export type ProductsControllerRetrieveProductsApiResponse =
    /** status 200 OK */ ProductSummaryDtoPagedList;
export type ProductsControllerRetrieveProductsApiArg = {
    merchantId?: number;
    productCode?: string;
    productName?: string;
    garmentType?: GarmentType;
    gender?: GarmentGender;
    categoryTags?: string;
    page?: number;
    pageSize?: number;
};
export type ProductsControllerUpdateProductApiResponse =
    /** status 200 OK */ ProductSummaryDto;
export type ProductsControllerUpdateProductApiArg = {
    merchantId: number;
    productCode: string;
    body: {
        "Product.Id"?: number;
        "Product.MerchantId"?: number;
        "Product.Name"?: string;
        "Product.ProductCode"?: string;
        "Product.GarmentType"?: GarmentType;
        "Product.Gender"?: GarmentGender;
        "Product.LastModified"?: string;
        "Product.HasSizing"?: boolean;
        "Product.CategoryTags"?: string[];
        "Product.ImageUrl"?: string;
        MerchantId?: number;
        Image?: Blob;
    };
};
export type ProductsControllerDeleteProductApiResponse = unknown;
export type ProductsControllerDeleteProductApiArg = {
    merchantId: number;
    productCode: string;
};
export type ProductsControllerRetrieveMerchantProductApiResponse =
    /** status 200 OK */ ProductDetailDto;
export type ProductsControllerRetrieveMerchantProductApiArg = {
    merchantId: number;
    productCode: string;
};
export type ProductsControllerRetrieveSizeChartCsvApiResponse =
    /** status 200 OK */ string;
export type ProductsControllerRetrieveSizeChartCsvApiArg = {
    merchantId: number;
    productCode: string;
};
export type ProductsControllerUpdateSizeChartApiResponse = unknown;
export type ProductsControllerUpdateSizeChartApiArg = {
    merchantId: number;
    productCode: string;
    body: {
        chartCsv?: Blob;
    };
};
export type ProductsControllerRetrieveDimensionsForScanTypeApiResponse =
    /** status 200 OK */ string[];
export type ProductsControllerRetrieveDimensionsForScanTypeApiArg = {
    scanType: ScanType;
};
export type ProductsControllerBulkProductImportApiResponse =
    /** status 200 OK */ string;
export type ProductsControllerBulkProductImportApiArg = {
    merchantId: number;
    body: {
        productsCsv?: Blob;
    };
};
export type ProductsControllerBulkProductUpdateApiResponse =
    /** status 200 OK */ string;
export type ProductsControllerBulkProductUpdateApiArg = {
    merchantId: number;
    body: {
        productsCsv?: Blob;
    };
};
export type ProductsControllerRetrieveBulkProductsApiResponse = unknown;
export type ProductsControllerRetrieveBulkProductsApiArg = {
    merchantId: number;
    example?: boolean;
};
export type ReportsControllerRetrieveProductSizingReportApiResponse =
    /** status 200 OK */ ProductSizingReportDto[];
export type ReportsControllerRetrieveProductSizingReportApiArg = {
    merchantId?: number;
    garmentTypes?: string;
    categoryTags?: string;
};
export type SizeMatchControllerGetFitMetadataApiResponse =
    /** status 200 OK */ {
        [key: string]: DimensionFitMetadataItemDto;
    };
export type SizeMatchControllerGetFitMetadataApiArg = {
    retrieveFitMetadataQuery: RetrieveFitMetadataQuery;
};
export type UserContextControllerRetrieveUserContextApiResponse =
    /** status 200 OK */ MerchantUserContext;
export type UserContextControllerRetrieveUserContextApiArg = void;
export type BoddRegion = "Unknown" | "AU" | "US" | "USWEST";
export type BoddCountry = "Au" | "Ca" | "Nz" | "Th" | "Us";
export type ScanImageConfiguration = "None" | "Profile" | "Detailed";
export type MerchantDto = {
    id?: number;
    name?: string | null;
    registeredAddress?: string | null;
    businessHqAddress?: string | null;
    taxId?: string | null;
    enquiryEmail?: string | null;
    website?: string | null;
    enquiryPhoneNumber?: string | null;
    region?: BoddRegion;
    country?: BoddCountry;
    qualityAssuranceHoldEnabled?: boolean;
    sizeMatchEnabled?: boolean;
    rawScanDownloadEnabled?: boolean;
    singleSignOnDomains?: string | null;
    allowedEmailDomains?: string | null;
    scanImageConfiguration?: ScanImageConfiguration;
};
export type MerchantDtoPagedList = {
    items?: MerchantDto[] | null;
    pageNumber?: number;
    totalPages?: number;
    totalCount?: number;
};
export type CreateMerchantCommand = {
    merchant?: MerchantDto;
};
export type AccessLevel =
    | "None"
    | "Bodd"
    | "QualityAssurance"
    | "MerchantAdmin"
    | "Merchant"
    | "MerchantOwner"
    | "SystemOwner";
export type UserDto = {
    email?: string | null;
    emailVerified?: boolean;
    loginsCount?: string | null;
    lastLogin?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    merchantId?: number | null;
    blocked?: boolean | null;
    accessLevel?: AccessLevel;
    created?: string;
    userId?: string | null;
    userGroupIds?: number[] | null;
};
export type UserDtoPagedList = {
    items?: UserDto[] | null;
    pageNumber?: number;
    totalPages?: number;
    totalCount?: number;
};
export type UserGroupDto = {
    id?: number;
    name?: string | null;
};
export type CreateMerchantUserCommand = {
    merchantId?: number;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    accessLevel?: AccessLevel;
    userGroupIds?: number[] | null;
};
export type UpdateMerchantUserCommand = {
    merchantId?: number;
    userId?: string | null;
    user?: UserDto;
};
export type CategoryDto = {
    name?: string | null;
};
export type AttachmentDto = {
    id?: number;
    fileName?: string | null;
    url?: string | null;
};
export type GarmentType =
    | "Shirt"
    | "TShirt"
    | "Outerwear"
    | "Shorts"
    | "Trousers"
    | "Skirt"
    | "FullBody"
    | "Jumper"
    | "Footwear"
    | "Belt"
    | "Gloves"
    | "Accessories"
    | "Other";
export type GarmentGender = "Male" | "Female" | "Unisex";
export type ProductSummaryDto = {
    id?: number;
    merchantId?: number;
    name?: string | null;
    productCode?: string | null;
    garmentType?: GarmentType;
    gender?: GarmentGender;
    lastModified?: string;
    hasSizing?: boolean;
    categoryTags?: string[] | null;
    imageUrl?: string | null;
};
export type ProductFitExampleDto = {
    title?: string | null;
    sizeTaken?: string | null;
    scanId?: string;
};
export type ProductSummaryDtoPagedList = {
    items?: ProductSummaryDto[] | null;
    pageNumber?: number;
    totalPages?: number;
    totalCount?: number;
};
export type ChartDimension = {
    measurementCode?: string | null;
    threshold?: number;
};
export type Size = {
    label?: string | null;
    order?: number;
    dimensions?: ChartDimension[] | null;
    sizeCode?: string | null;
};
export type SizeChartAlterationTemplate = {
    dimension?: string | null;
    threshold?: number;
    margin?: number | null;
};
export type DimensionOffset = {
    dimension?: string | null;
    offset?: number;
};
export type Fit = {
    fitName?: string | null;
    alternativeFitCriteria?: string | null;
    enabled?: boolean;
    sizes?: Size[] | null;
    alterations?: SizeChartAlterationTemplate[] | null;
    offsets?: DimensionOffset[] | null;
};
export type SizeChartDto = {
    productCode?: string | null;
    fits?: Fit[] | null;
};
export type ProductDetailDto = {
    id?: number;
    merchantId?: number;
    name?: string | null;
    productCode?: string | null;
    garmentType?: GarmentType;
    gender?: GarmentGender;
    lastModified?: string;
    sizing?: SizeChartDto;
    categoryTags?: string[] | null;
    fitAttachments?: AttachmentDto[] | null;
    fitExamples?: ProductFitExampleDto[] | null;
    fitDescription?: string | null;
    fitApproved?: boolean;
    imageUrl?: string | null;
};
export type ScanType =
    | "BodyScan"
    | "FootScan"
    | "WellnessScan"
    | "HandScan"
    | "HeadScan"
    | "HealthScan";
export type ProductSizingReportSizeDto = {
    sizeLabel?: string | null;
    dimensionThreshold?: number;
    offset?: number;
};
export type ProductSizingReportDto = {
    productId?: number;
    merchantId?: number;
    productCode?: string | null;
    productName?: string | null;
    categoryTags?: string[] | null;
    garmentType?: GarmentType;
    dimensionCode?: string | null;
    fitName?: string | null;
    sizes?: ProductSizingReportSizeDto[] | null;
};
export type DimensionFitMetadataItemDto = {
    bodyMeasurement?: number;
    distanceToThreshold?: number;
    proximityToThreshold?: number;
};
export type Measurement = {
    key?: string | null;
    value?: number;
};
export type RetrieveFitMetadataQuery = {
    size?: string | null;
    comparisonSize?: string | null;
    merchantId?: number;
    productCode?: string | null;
    fit?: string | null;
    measurements?: Measurement[] | null;
};
export type MerchantSummaryDto = {
    id?: number;
    name?: string | null;
    region?: BoddRegion;
    qualityAssuranceHoldEnabled?: boolean;
    sizeMatchEnabled?: boolean;
    rawScanDownloadEnabled?: boolean;
    scanImageConfiguration?: ScanImageConfiguration;
};
export type MerchantUserContext = {
    allMerchants?: MerchantSummaryDto[] | null;
    userMerchant?: MerchantSummaryDto;
    regionMap?: {
        Unknown?: string;
        AU?: string;
        US?: string;
        USWEST?: string;
    } | null;
};
export const {
    useMerchantsControllerRetrieveMerchantsQuery,
    useMerchantsControllerCreateMerchantMutation,
    useMerchantsControllerRetrieveMerchantQuery,
    useMerchantsControllerUpdateMerchantMutation,
    useMerchantsControllerUpdateMerchantConfigurationMutation,
    useMerchantUsersControllerRetrieveMerchantUsersQuery,
    useMerchantUsersControllerRetrieveUserGroupsQuery,
    useMerchantUsersControllerUpdateUserGroupMutation,
    useMerchantUsersControllerCreateMerchantUserMutation,
    useMerchantUsersControllerUpdateMerchantUserMutation,
    useProductCategoriesControllerCreateProductCategoryMutation,
    useProductCategoriesControllerRetrieveCategoriesQuery,
    useProductCategoriesControllerDeleteProductCategoryMutation,
    useProductFunctionalFitControllerInitiateAttachmentUploadMutation,
    useProductFunctionalFitControllerUpdateProductFitAssessmentMutation,
    useProductFunctionalFitControllerCompleteAttachmentUploadMutation,
    useProductFunctionalFitControllerDeleteAttachmentMutation,
    useProductFunctionalFitControllerCreateFitExampleMutation,
    useProductFunctionalFitControllerDeleteFitExampleMutation,
    useProductsControllerCreateProductMutation,
    useProductsControllerRetrieveProductsQuery,
    useProductsControllerUpdateProductMutation,
    useProductsControllerDeleteProductMutation,
    useProductsControllerRetrieveMerchantProductQuery,
    useProductsControllerRetrieveSizeChartCsvQuery,
    useProductsControllerUpdateSizeChartMutation,
    useProductsControllerRetrieveDimensionsForScanTypeQuery,
    useProductsControllerBulkProductImportMutation,
    useProductsControllerBulkProductUpdateMutation,
    useProductsControllerRetrieveBulkProductsQuery,
    useReportsControllerRetrieveProductSizingReportQuery,
    useSizeMatchControllerGetFitMetadataMutation,
    useUserContextControllerRetrieveUserContextQuery,
} = injectedRtkApi;
